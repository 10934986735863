import React, {Component} from 'react';
import { checkOptions, checkLineObject } from 'd3quickgraph/src/check';
import { createSvg, createAxis, calculateBounds, drawDataLine  } from 'd3quickgraph/src/draw';
// import isObject from 'lodash/isObject';

export default class SampleLine extends Component {

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    console.log('SampleLine.componentDidMount');
    this.drawSampleLine();
  }

  componentDidUpdate () {
    console.log('SampleLine.componentDidUpdate', 'props', this.props);
    this.drawSampleLine();
  }

  // componentWillReceiveProps(nextProps){
  //   if(nextProps.value !== this.props.value){
  //     this.setState({count:nextProps.value});
  //   }
  // }

  // shouldComponentUpdate(nextProps) {
  //   return nextProps.color !== this.state.color || nextProps.width !== this.state.width;
  // }

  drawSampleLine () {
    console.log('drawSampleLine', 'props', this.props);
    // const mainContainer = document.getElementById('line-main-container');
    // const graphContainer = document.getElementById('line-graph-container');
    const mainContainer = this._mainContainer;
    const graphContainer = this._graphContainer;
    let options = {
      mainContainer,
      graphContainer,
      aspectRatio: 50,
      widthPercent: 90,
      margin : {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      grid: false,
      ticks: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      axis: {
        top: {
          width: 0
        },
        bottom: {
          width: 0
        },
        left: {
          width: 0
        },
        right: {
          width: 0
        }
      }
    };
    const { width, color, dashArray } = this.props;
    options = checkOptions(options);
    console.log('options', options);
    const svg = createSvg(options);
    const lineObject = {
      name: 'sampleLine',
      data: [
        {x: 0, y: 0},
        {x: 1, y: 0}
      ],
      width,
      color,
      dashArray
    };
    console.log('calling checkLineObject');
    checkLineObject(lineObject);
    console.log('lineObject', lineObject);
    const lines = { sampleLine: lineObject };
    const bounds = calculateBounds({ lines });
    console.log('bounds', bounds);
    const { scale } = createAxis(svg, options, bounds);
    console.log('scale', scale);
    drawDataLine({ canvas: svg, scale, options, bounds, lineObject });
  }

  render () {
    return (
      <div ref={(c) => this._mainContainer = c} >
        <div style={{marginLeft: '5%'}} ref={(c) => this._graphContainer = c} >
        </div>
      </div>
    );
  }
}