/**
 * Default values for GraphOptions object
 *
 * @type {{
 *   padding: {
 *     top: number,
 *     left: number,
 *     bottom: number,
 *     right: number
 *   },
 *   maxWidth: number,
 *   widthPercent: null,
 *   margin: {
 *     top: number,
 *     left: number,
 *     bottom: number,
 *     right: number
 *   },
 *   grid: {
 *     color: string,
 *     width: number,
 *     dashArray: number[]
 *   },
 *   line: {
 *     color: string,
 *     width: number
 *   },
 *   aspectRatio: number,
 *   axisRounding: {
 *     yMin: {digits: number, up: boolean},
 *     yMax: {digits: number, up: boolean},
 *     xMax: {digits: number, up: boolean},
 *     xMin: {digits: number, up: boolean}
 *   },
 *   point: {
 *     r: number,
 *     shape: string,
 *     fill: string,
 *     stroke: string
 *   }
 * }}
 */
export var defaultOptions = {
  mainContainer: null,
  graphContainer: null,
  margin: {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10
  },
  padding: {
    top: 40,
    bottom: 40,
    left: 40,
    right: 40
  },
  widthPercent: null,
  maxWidth: 800,
  aspectRatio: 4 / 3,
  axisRounding: {
    xMin: {
      up: false,
      digits: 0
    },
    yMin: {
      up: false,
      digits: 0
    },
    xMax: {
      up: true,
      digits: 0
    },
    yMax: {
      up: true,
      digits: 0
    }
  },
  grid: {
    color: '#a0a0a0',
    width: 1,
    dashArray: [1, 1]
  },
  line: {
    color: 'black',
    width: 1
  },
  point: {
    shape: 'circle',
    r: 10,
    stroke: 'black',
    fill: 'red'
  }
};