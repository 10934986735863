import React, {Component} from 'react';
import Collapse from 'react-bootstrap/Collapse';
import LinePanel from './LinePanel';
import QuickGraph from './QuickGraph';
import cloneDeep from 'lodash/cloneDeep';

const initialGridOptions = {
  width: 1,
  color: '#00ff00',
  dashArray: null
};
const initialLineOptions = {
  width: 5,
  color: '#000000',
  dashArray: null
};

/**
 * The QuickGraphPanel has 2 child components:
 *   1.  A LinePanel that controls the options for the data line
 *   2.  A LinePanel that controls the options for the grid lines
 *
 * BootStrap Collapse is use to animate the slide open of the panel.
 */
export default class QuickGraphPanel extends Component {

  constructor (props) {
    super(props);
    this.state = {
      show: false,
      gridOptions: cloneDeep(initialGridOptions),
      lineOptions: cloneDeep(initialLineOptions)
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  handleShow () {
    console.log('QuickGraphPanel.handleShow');
    this.setState( prevState => (
      { show: !prevState.show }
    ));
  }

  handleApply () {
    const lineOptions = this._linePanel.getLineOptions();
    const gridOptions = this._gridPanel.getLineOptions();
    console.log('gridOptions', gridOptions);
    this.setState( { gridOptions, lineOptions });
  }

  render () {
    const { state } = this;
    const { lineOptions, gridOptions } = state;
    console.log('QuickGraphPanel.render', 'state', state);

    return (
      <div>
        <Collapse
          timeout={200}
          in={state.show}
        >
          <div>
            <LinePanel title="Data Line Options" showRestore { ...this.state.lineOptions } ref={(c) => this._linePanel = c}  />
            <LinePanel title="Grid Line Options" showRestore { ...this.state.gridOptions } ref={(c) => this._gridPanel = c}  />
          </div>
        </Collapse>
        <QuickGraph gridOptions={gridOptions} lineOptions={lineOptions} />
      </div>
    );
  }
}