import React, {Component} from 'react';
// import InputColor from 'react-input-color';
import { SketchPicker } from 'react-color';
import DashLineInput from './DashLineInput';
import SampleLine from './SampleLine';

const spanClasses = 'col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2';
const inputClasses = 'col-10 col-sm-7 col-md-7 col-lg-7 col-xl-7';
const defaultColor = '#00ff00';
const defaultWidth = 1;
const defaultTitleStyle = {
  textAlign: 'left',
  paddingLeft: '3em'
};

/**
 * This component has 4 child components:
 *   1.  An simple text input for the line width
 *   2.  SketchPicker: an imported React component for selecting color & opacity (HSL)
 *   3.  DashLineInput: a drop-down box selecting the number of dashes & spaces, and their values
 *   4.  SampleLine: a stripped-down d3quickgraph component displaying a sample of a line drawn with
 *       the selected line options
 */
export default class LinePanel extends Component {

  constructor (props) {
    super(props);
    this.state = {
      displaySketch: false,
      initialColor: props.color || defaultColor,
      color: props.color || defaultColor,
      initialWidth: props.width || defaultWidth,
      width: props.width || defaultWidth,
      dashArray: props.dashArray || [],
    };
    this.state.current = {
      color: this.state.color,
      width: this.state.width,
      dashArray: this.state.dashArray
    };
    this.toggleSketch = this.toggleSketch.bind(this);
    this.setColor = this.setColor.bind(this);
    this.setWidth = this.setWidth.bind(this);
    this.setDashArray = this.setDashArray.bind(this);
    this.getLineOptions = this.getLineOptions.bind(this);
    this.restoreCurrent = this.restoreCurrent.bind(this);
  }

  setColor(color) {
    const {toggleSketch} = this;
    color && this.setState(
      { color: color.hex },
      toggleSketch
    );
  }

  setWidth(evt) {
    evt.preventDefault();
    const width = Number(this._lineWidth.value);
    if (width >= 0) {
      this.setState( { width } );
    } else {
      this._lineWidth.value = this.state.width;
    }
  }

  setDashArray(dashArray) {
    this.setState({ dashArray });
  }

  restoreCurrent() {
    const { state } = this;
    const lineWidth = this._lineWidth;
    this.setState(
      {
        color: state.current.color,
        width: state.current.width,
        dashArray: [ ...state.current.dashArray ]
      },
      () => {
        lineWidth.value = state.current.width;
      }
    );
  }

  toggleSketch(evt) {
    evt && evt.preventDefault();
    this.setState( prevState => (
      { displaySketch: !prevState.displaySketch }
    ));
  }

  componentDidMount () {
    const { state } = this;
    this._lineWidth.value = state.initialWidth;
  }

  getLineOptions (updateCurrent) {
    const { width, color, dashArray } = this.state;
    if (updateCurrent) {
      this.setState( {
        current: { width, color, dashArray: [ ...dashArray ] }
      });
    }
    return {
      width,
      color,
      dashArray
    };
  }

  render () {
    const { color, width, dashArray, displaySketch } = this.state;
    console.log('LinePanel.render', 'state', this.state);
    const { props, setWidth, setColor, setDashArray, restoreCurrent } = this;
    const colorStyle = {
      margin: '0 1em',
      height: '1.5em',
      width: '55%',
      backgroundColor: color,
      border: '1px solid #aaa',
      borderRadius: '5px 5px',
      cursor: 'pointer'
    };
    const sketchPickerStyle = {
      width: '250px'
    };
    if (displaySketch) {
      colorStyle.width = '30%';
    }
    const sampleDivStyle = {
      marginLeft: '5%',
      width: '90%',
      padding: '1em  0',
      marginTop: '1em',
      border: '1px solid #aaa',
      borderRadius: '5px 5px'
    };
    const colorTitle = 'Click to toggle color picker';
    const titleStyle = Object.assign(defaultTitleStyle, props.titleStyle);
    const restoreButton = (
      props.showRestore ?
        <button className="btn btn-outline-primary" style={{float: 'right', marginRight: '2em'}} onClick={restoreCurrent}>Restore Last Values</button> :
        ''
    );
    const header = props.title ? (
      <div className="container-fluid card-header" style={titleStyle}>{props.title}{restoreButton}</div>
    ) : '';

    return (
      <div className="card">
        {header}
        <div className="container-fluid card-body">
          <form onSubmit={(ev) => ev.preventDefault()} action='#'>
            <div className="row form-group">
              <span className={spanClasses}>Line Width</span>
              <div className={inputClasses}>
                <input onChange={setWidth} className='form-control roundedBorder default-input-styles' type='text' name="line-width" ref={(c) => this._lineWidth = c}  />
              </div>
            </div>
            <div className="row form-group">
              <span className={spanClasses}>Line Color</span>
              <div style={ colorStyle } title={colorTitle} onClick={this.toggleSketch}></div>
              {displaySketch &&
              <SketchPicker
                style={sketchPickerStyle}
                color={color}
                onChangeComplete={setColor}
              />
              }
            </div>
            <div className="row form-group">
              <span className={spanClasses}>Dash Options</span>
              <div className={inputClasses} style={{ textAlign: 'left' }}>
                <DashLineInput dashLength={dashArray.length} setDashArray={setDashArray}/>
              </div>
            </div>
          </form>
          <div style={ sampleDivStyle }>
            <h6>Sample Line</h6>
            <SampleLine color={color} width={width} dashArray={dashArray} />
          </div>
        </div>
      </div>
    );
  }
}
