import _objectSpread from "/Users/stefan/www/pluralsight/d3quickgraph-react/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _toConsumableArray from "/Users/stefan/www/pluralsight/d3quickgraph-react/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { defaultOptions } from './default-options.js';
import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { axisLeft, axisBottom, axisRight, axisTop } from 'd3-axis';
import { format } from 'd3-format';
import { line } from 'd3-shape';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import { checkObject, checkOptions, checkLineObject, checkPointGroupObject, isNonNegativeInteger, isAxis, isValidSide } from './check';
/**
 * Bounds of the graph's axes
 * @typedef {object} Bounds
 * @property {number} xMin
 * @property {number} xMax
 * @property {number} yMin
 * @property {number} yMax
 */

/**
 * * Creates an HTML SVG Element and attaches it to the $graphContainer
 * * Calculates width and height graphContaier using provided aspect ratio and minimumWidth
 * * Caluclates width and height of svg using provided margins
 * * Sets attributes and style of the svg to allow it to be transformed
 * * If GraphOptions.updateSvg === true, the existing main-container, graph-container and svg will be updated
 * @function
 * @private
 * @param {GraphOptions} options GraphOptions
 * @returns {selection}
 */

export var createSvg = function createSvg(options) {
  checkObject(options, 'createSvg');
  options = checkOptions(options);
  var svg = null;

  if (options.updateSvg === true) {
    svg = select(options.$graphContainer[0]).select('svg');

    if (svg.empty()) {
      throw new Error('createSvg called with options.updateSvg === true, but svg does not exist');
    }
  } else {
    /*
     * Create a new svg element and append to the graph container
     */
    svg = select(options.$graphContainer[0]).select('svg');

    if (!svg.empty()) {
      svg.remove();
    }

    select(options.$graphContainer[0]).append('svg');
    svg = select(options.$graphContainer[0]).select('svg');
  }

  if (options.widthPercent) {
    options.$mainContainer.width(options.widthPercent + '%');
  } else {
    options.$mainContainer.width(options.graphWidth);
  }

  var width = options.graphWidth - options.margin.left - options.margin.right;
  var height = options.graphHeight - options.margin.top - options.margin.bottom;
  svg
  /*
   * The next 3 lines are to allow resizing the graph with a transform
   */
  .attr('preserveAspectRatio', 'xMinYMin meet').attr('viewBox', "0 0 ".concat(width, " ").concat(height)).classed('svg-content', true).style('display', 'block') // .style('width', width)
  // .style('height', height)
  .style('margin-left', "".concat(options.margin.left, "px")).style('margin-right', "".concat(options.margin.right, "px")).style('margin-top', "".concat(options.margin.top, "px")).style('margin-bottom', "".concat(options.margin.bottom, "px"));
  return svg;
};
/**
 * Returns axis path attributes, values and style
 * If the value is "false", then the line width is returned as zero and no line will be visible.
 * @function
 * @private
 * @param {AxisOptions} axisOptions AxisOptions
 * @param {string} side one of 'top', 'right', 'bottom', 'left'
 * @returns {{strokeWidth: string, dashStyle: string, width: string, strokeColor: string, dashArray: string, stroke: string}}
 */

var getSideOptions = function getSideOptions(axisOptions, side) {
  var sideOptions = isObject(axisOptions) && isObject(axisOptions[side]) ? axisOptions[side] : {};
  var stroke = 'stroke';
  var strokeColor = sideOptions.color || defaultOptions.line.color;
  var width = 'stroke-width';
  var strokeWidth = isNumber(sideOptions.width) ? sideOptions.width : defaultOptions.line.width;
  if (axisOptions[side] === false) strokeWidth = 0;
  var dashStyle = Array.isArray(sideOptions.dashArray) ? 'stroke-dasharray' : '';
  var dashArray = Array.isArray(sideOptions.dashArray) ? "".concat(sideOptions.dashArray.join()) : '';
  return {
    stroke: stroke,
    strokeColor: strokeColor,
    width: width,
    strokeWidth: strokeWidth,
    dashStyle: dashStyle,
    dashArray: dashArray
  };
};
/**
 * Adds top, bottom, left and right axes to the svg
 *
 * If graphOptions.ticks[side] === false or a non-negative integer, set number of ticks as specified
 * @private
 * @function
 * @param {selection} svg Selection object containing the SVG Element
 * @param {AxisOptions} options AxisOptions
 * @param {Bounds} bounds Bounds
 * @returns {object} return object has "axis" and "scale" properties
 */


export var createAxis = function createAxis(svg, options, bounds) {
  var axis = {};
  var scale = {}; // const { ticks } = options

  var ticks = isObject(options.ticks) ? options.ticks : {
    top: true,
    right: true,
    left: true,
    bottom: true
  };
  var axisOptions = options.axis || {};
  var sideOptions;
  sideOptions = getSideOptions(axisOptions, 'bottom');
  scale.x = scaleLinear().domain([bounds.xMin, bounds.xMax]).range([0, options.svgWidth]);
  axis.bottom = axisBottom(scale.x); // ticks !== false && ticks.bottom !== false && axis.bottom.ticks(ticks.bottom)

  ticks.bottom !== true && isNonNegativeInteger(ticks.bottom) && axis.bottom.ticks(ticks.bottom);
  ticks.bottom === false && axis.bottom.ticks(0);
  var dxBottom = options.padding.left;
  var dyBottom = options.svgHeight + options.padding.top;
  svg.append('g').attr('class', 'axis axis-bottom').attr('transform', "translate(".concat(dxBottom, ", ").concat(dyBottom, ")")).call(axis.bottom).select('path').attr(sideOptions.stroke, sideOptions.strokeColor).attr(sideOptions.width, sideOptions.strokeWidth).style(sideOptions.dashStyle, sideOptions.dashArray);
  sideOptions = getSideOptions(axisOptions, 'top');
  axis.top = axisTop(scale.x); // ticks.top !== true && isNonNegativeInteger(ticks.top) && axis.top.ticks(ticks.top)
  // ticks.top === false && axis.top.ticks(0)

  addTicks({
    axis: axis,
    options: options,
    side: 'top'
  });
  var dxTop = options.padding.left;
  var dyTop = options.padding.top;
  svg.append('g').attr('class', 'axis axis-top').attr('transform', "translate(".concat(dxTop, ", ").concat(dyTop, ")")).call(axis.top).select('path').attr(sideOptions.stroke, sideOptions.strokeColor).attr(sideOptions.width, sideOptions.strokeWidth).style(sideOptions.dashStyle, sideOptions.dashArray);
  scale.y = scaleLinear().domain([bounds.yMin, bounds.yMax]).range([options.svgHeight, 0]);
  sideOptions = getSideOptions(axisOptions, 'left');
  axis.left = axisLeft(scale.y); // ticks !== false && ticks.left !== false && axis.left.ticks(ticks.left)
  // ticks.left !== true && isNonNegativeInteger(ticks.left) && axis.left.ticks(ticks.left)
  // ticks.left === false && axis.left.ticks(0)

  addTicks({
    axis: axis,
    options: options,
    side: 'left'
  });
  var dxLeft = options.padding.left;
  var dyLeft = options.padding.top;
  svg.append('g').attr('class', 'axis axis-left').attr('transform', "translate(".concat(dxLeft, ", ").concat(dyLeft, ")")).call(axis.left).select('path').attr(sideOptions.stroke, sideOptions.strokeColor).attr(sideOptions.width, sideOptions.strokeWidth).style(sideOptions.dashStyle, sideOptions.dashArray);
  sideOptions = getSideOptions(axisOptions, 'right');
  axis.right = axisRight(scale.y); // ticks !== false && ticks.right !== false && axis.right.ticks(ticks.right)
  // ticks.right !== true && isNonNegativeInteger(ticks.right) && axis.right.ticks(ticks.right)
  // ticks.right === false && axis.right.ticks(0)

  addTicks({
    axis: axis,
    options: options,
    side: 'right'
  });
  var dxRight = options.padding.left + options.svgWidth;
  var dyRight = options.padding.top;
  svg.append('g').attr('class', 'axis axis-right').attr('transform', "translate(".concat(dxRight, ", ").concat(dyRight, ")")).call(axis.right).select('path').attr(sideOptions.stroke, sideOptions.strokeColor).attr(sideOptions.width, sideOptions.strokeWidth).style(sideOptions.dashStyle, sideOptions.dashArray);
  return {
    axis: axis,
    scale: scale
  };
};
/**
 * If addTicks called stand-alone (not from createAxis), then the returned axis must be added back to the Svg
 * using updateAxis(axis, side)
 *
 * @function
 * @private
 * @param {object} axis
 * @param {GraphOptions} options
 * @param {TickOptions|boolean} options.ticks
 * @param {string} side
 */

export var addTicks = function addTicks(_ref) {
  var axis = _ref.axis,
      options = _ref.options,
      side = _ref.side;

  if (!isObject(options)) {
    throw new Error('addTicks must be passed an options object');
  }

  if (options.ticks === true) return;

  if (!isValidSide(side)) {
    throw new Error("addTicks passed invalid side \"".concat(side, "\""));
  }

  if (!isObject(axis) || !isAxis(axis[side])) {
    // TODO: fix this
    // console.log('addTicks must be passed an object keyed by sides containing a d3-axis')
    return; // throw new Error('addTicks must be passed an object keyed by sides containing a d3-axis')
  }

  var sideAxis = axis[side];

  if (options.ticks === false) {
    sideAxis.ticks(0);
    return;
  }

  var tickOptions = options.ticks;
  if (!isObject(tickOptions)) return;

  if (Array.isArray(tickOptions.values)) {
    sideAxis.tickValues(tickOptions.values);
  }

  if (isString(tickOptions.format)) {
    sideAxis.tickFormat(format(tickOptions.format));
  }
};
/**
 * * Calculates bounds based on extrema of all lines and pointGroups
 * * GraphAxisRounding object can be used to set how the bounds are calculated
 * @public
 * @function
 * @param {object} obj
 * @param {Array<LineObject>} obj.lines Array of LineObjects
 * @param {Array<PointGroupObject>} obj.groups Array of PointGroupObjects
 * @param {GraphAxisRounding} obj.axisRounding GraphAxisRounding object
 * @returns {Bounds}
 */

export var calculateBounds = function calculateBounds(_ref2) {
  var lines = _ref2.lines,
      groups = _ref2.groups,
      axisRounding = _ref2.axisRounding;

  /*
   * @type Bounds
   */
  var bounds = {
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null
  };
  [lines, groups].forEach(function (obj) {
    if (isObject(obj)) {
      var names = Object.keys(obj);
      names.forEach(function (name) {
        var datum = obj[name];

        if (datum) {
          var xValues = datum.data.map(function (v) {
            return v.x;
          });
          var yValues = datum.data.map(function (v) {
            return v.y;
          });
          bounds.xMin = isNumber(bounds.xMin) ? Math.min.apply(Math, [bounds.xMin].concat(_toConsumableArray(xValues))) : Math.min.apply(Math, _toConsumableArray(xValues));
          bounds.yMin = isNumber(bounds.yMin) ? Math.min.apply(Math, [bounds.yMin].concat(_toConsumableArray(yValues))) : Math.min.apply(Math, _toConsumableArray(yValues));
          bounds.xMax = isNumber(bounds.xMax) ? Math.max.apply(Math, [bounds.xMax].concat(_toConsumableArray(xValues))) : Math.max.apply(Math, _toConsumableArray(xValues));
          bounds.yMax = isNumber(bounds.yMax) ? Math.max.apply(Math, [bounds.yMax].concat(_toConsumableArray(yValues))) : Math.max.apply(Math, _toConsumableArray(yValues));
        }
      });
    }
  });

  if (isObject(axisRounding)) {
    ['xMin', 'xMax', 'yMin', 'yMax'].forEach(function (key) {
      var roundingOptions = axisRounding[key];

      if (isNumber(roundingOptions.value)) {
        bounds[key] = roundingOptions.value;
      } else {
        bounds[key] = customRound(_objectSpread({}, roundingOptions, {
          value: bounds[key]
        }));
      }
    });
  }

  return bounds;
};
/**
 * Appends line to svg
 * @private
 * @function
 * @param {object} obj
 * @param {selection} obj.canvas Selection object containing SVG Element
 * @param {object} obj.scale D3 Scale object
 * @param {function} obj.scale.x D3 function for X Scale
 * @param {function} obj.scale.y D3 function for Y Scale
 * @param {GraphOptions} obj.options GraphOptions object
 * @param {Bounds} obj.bounds Bounds object used for clipping data outside the bounds
 * @param {LineObject} obj.lineObject LineObject being drawn
 */

export var drawDataLine = function drawDataLine(_ref3) {
  var canvas = _ref3.canvas,
      scale = _ref3.scale,
      options = _ref3.options,
      bounds = _ref3.bounds,
      lineObject = _ref3.lineObject;
  checkLineObject(lineObject);
  var data = lineObject.data;

  var inBounds = function inBounds(_ref4) {
    var x = _ref4.x,
        y = _ref4.y;
    return x >= bounds.xMin && x <= bounds.xMax && y >= bounds.yMin && y <= bounds.yMax;
  };

  var dataLine = line().x(function (d) {
    return scale.x(d.x);
  }).y(function (d) {
    return scale.y(d.y);
  }).defined(inBounds);
  var color = lineObject.color || defaultOptions.line.color;
  var width = lineObject.width || defaultOptions.line.width;
  var dashStyle = Array.isArray(lineObject.dashArray) ? 'stroke-dasharray' : '';
  var dashArray = Array.isArray(lineObject.dashArray) ? "".concat(lineObject.dashArray.join()) : '';
  canvas.append('path').data([data]).attr('class', "line ".concat(lineObject.name)).attr('stroke', color).attr('stroke-width', width).style(dashStyle, dashArray).attr('fill', 'none').attr('transform', "translate(".concat(options.padding.left, ", ").concat(options.padding.top, ")")).attr('d', dataLine);
};
/**
 * Draw all points in the pointGroupObject
 *
 * @private
 * @function
 * @param {object} obj
 * @param {selection} obj.canvas Selection object containing SVG Element
 * @param {object} obj.scale D3 Scale object
 * @param {GraphOptions} obj.options GraphOptions object
 * @param {Bounds} obj.bounds Bounds object used for clipping data outside the Bounds
 * @param {PointGroupObject} obj.pointGroupObject Contains array of PointObjects being drawn
 */

export var drawPoints = function drawPoints(_ref5) {
  var canvas = _ref5.canvas,
      scale = _ref5.scale,
      options = _ref5.options,
      bounds = _ref5.bounds,
      pointGroupObject = _ref5.pointGroupObject;
  pointGroupObject.data.forEach(function (pointObject, i) {
    drawPoint({
      canvas: canvas,
      scale: scale,
      options: options,
      bounds: bounds,
      pointGroupObject: pointGroupObject,
      pointObject: pointObject,
      i: i
    });
  });
};
/**
 * * Create the svg group if it does not already exist
 * * Append a shape object to the group at the specified location
 * @private
 * @function
 * @param {object} obj
 * @param {selection} obj.canvas Selection object containing the SVG Element
 * @param {object} obj.scale D3 Scale object
 * @param {GraphOptions} obj.options GraphOptions
 * @param {Bounds} obj.bounds Bounds
 * @param {PointGroupObject} obj.pointGroupObject Parent object of the pointObject used to get the name
 * @param {PointObject} obj.pointObject PointObject being drawn
 * @param {number} obj.i index of PointObject in the PointGroupObject.data array
 * @returns {selection}
 */

export var drawPoint = function drawPoint(_ref6) {
  var canvas = _ref6.canvas,
      scale = _ref6.scale,
      options = _ref6.options,
      bounds = _ref6.bounds,
      pointGroupObject = _ref6.pointGroupObject,
      pointObject = _ref6.pointObject,
      i = _ref6.i;
  var groupName = ".group-".concat(pointObject.name);
  var group = canvas.selectAll(groupName);

  if (group.empty()) {
    group = canvas.append('g').attr('class', groupName.substr(1));
  }

  return drawShape({
    canvas: canvas,
    scale: scale,
    options: options,
    bounds: bounds,
    group: group,
    pointGroupObject: pointGroupObject,
    pointObject: pointObject,
    i: i
  });
};
/**
 * Append a shape object to the svg group at the specified location
 * @private
 * @function
 * @param {object} obj
 * @param {selection} obj.canvas Selection object containing SVG Element
 * @param {object} obj.scale properties x and y are D3 scale functions
 * @param {GraphOptions} obj.options GraphOptions
 * @param {Bounds} obj.bounds Bounds
 * @param {selection} obj.group Selection object containing all points in the PointGroup
 * @param {PointGroupObject} obj.pointGroupObject Parent object of the PointObject
 * @param {PointObject} obj.pointObject Point object  being drawn
 * @param {number} obj.i index of PointObject in the PointGroupObject.data array
 * @returns {selection}
 * @throws if pointGroupObject or pointOject contain invalid properties
 */

export var drawShape = function drawShape(_ref7) {
  var canvas = _ref7.canvas,
      scale = _ref7.scale,
      options = _ref7.options,
      bounds = _ref7.bounds,
      group = _ref7.group,
      pointGroupObject = _ref7.pointGroupObject,
      pointObject = _ref7.pointObject,
      i = _ref7.i;
  checkPointGroupObject(pointGroupObject);
  var x = scale.x(pointObject.x) + options.padding.left;
  var y = scale.y(pointObject.y) + options.padding.top;
  var className = "group-".concat(pointObject.name, "-").concat(i);
  var shape = pointObject.shape || pointGroupObject.shape;
  var fill = pointObject.fill || pointGroupObject.fill;
  var stroke = pointObject.stroke || pointGroupObject.stroke;
  var r = pointObject.r || pointGroupObject.r;

  switch (shape) {
    case 'circle':
      group.append('circle').attr('class', className).attr('r', r).attr('cx', x).attr('cy', y).attr('fill', fill).attr('stroke', stroke);
      break;

    default:
      break;
  }

  return group.select(".".concat(className));
};
/**
 * Used for calculating bounds for the graph that are rounded to the specified precision
 * @private
 * @param {object} obj
 * @param {boolean} obj.up if true round up, else down
 * @param {number} obj.value number being rounded
 * @param {number} obj.digits precision (siginficant digit) that is rounded to
 * @returns {number} rounded number
 */

export var customRound = function customRound(_ref8) {
  var up = _ref8.up,
      value = _ref8.value,
      digits = _ref8.digits;

  if (!isNumber(value)) {
    throw new Error('customRound must be passed "value" that is a number');
  }

  if (!isNumber(digits)) {
    throw new Error('customRound must be passed "digits" that is a number');
  }

  if (!isBoolean(up)) {
    throw new Error('customRound must be passed "up" that is a boolean');
  }

  if (!value) return 0;
  var sign = value / Math.abs(value);
  up = sign > 0 ? up : !up;
  value = Math.abs(value);
  var adjustment = up ? 0 : -1;
  var exponent = Math.floor(Math.log10(value));
  var increment = Math.pow(10, exponent - digits);
  var dividend = Math.ceil(value / increment) + adjustment;
  var rounded = parseFloat((dividend * increment).toPrecision(8));
  return sign * rounded;
};
/**
 * Append lines to the svg for the grid
 * @private
 * @function
 * @param {object} obj
 * @param {selection} obj.canvas Selection object containing the SVG Element
 * @param {object} obj.axis properties left, right, top and bottom each contain a scale property
 * @param {object} obj.scale properties x and y are scale functions
 * @param {GraphOptions} obj.options GraphOptions
 * @param {Bounds} obj.bounds Bounds
 */

export var drawGrid = function drawGrid(_ref9) {
  var canvas = _ref9.canvas,
      axis = _ref9.axis,
      scale = _ref9.scale,
      options = _ref9.options,
      bounds = _ref9.bounds;
  if (!canvas || !axis || !scale || !options || !bounds) return;

  if (options.grid === false) {
    return;
  }
  /*
   * Horizontal Grid
   */


  var leftTicks = isObject(options.ticks) && isNonNegativeInteger(options.ticks.left) ? options.ticks.left : null;
  var yTicks = axis.left.scale().ticks(leftTicks);

  if (yTicks.length) {
    if (yTicks[0] === bounds.yMin) {
      yTicks.shift();
    }

    if (yTicks[yTicks.length - 1] === bounds.yMax) {
      yTicks.pop();
    }

    yTicks.forEach(function (y, i) {
      var lineObject = {
        name: "xGrid-".concat(i),
        data: [{
          x: bounds.xMin,
          y: y
        }, {
          x: bounds.xMax,
          y: y
        }],
        width: options.grid.width,
        color: options.grid.color,
        dashArray: options.grid.dashArray
      };
      drawDataLine({
        canvas: canvas,
        scale: scale,
        options: options,
        bounds: bounds,
        lineObject: lineObject
      });
    });
  }
  /*
   * Vertical Grid
   */


  var bottomTicks = isObject(options.ticks) && isNonNegativeInteger(options.ticks.bottom) ? options.ticks.bottom : null;
  var xTicks = axis.bottom.scale().ticks(bottomTicks);

  if (xTicks.length) {
    if (xTicks[0] === bounds.xMin) {
      xTicks.shift();
    }

    if (xTicks[xTicks.length - 1] === bounds.xMax) {
      xTicks.pop();
    }

    xTicks.forEach(function (x, i) {
      var lineObject = {
        name: "yGrid-".concat(i),
        data: [{
          x: x,
          y: bounds.yMin
        }, {
          x: x,
          y: bounds.yMax
        }],
        width: options.grid.width,
        color: options.grid.color,
        dashArray: options.grid.dashArray
      };
      drawDataLine({
        canvas: canvas,
        scale: scale,
        options: options,
        bounds: bounds,
        lineObject: lineObject
      });
    });
  }
};
/**
 * Remove all paths for the grid from the svg
 * @private
 * @function
 * @param {object} obj
 * @param {selection} obj.canvas Selection object containing the SVG Element
 */

export var clearGrid = function clearGrid(_ref10) {
  var canvas = _ref10.canvas;
  var xGridLines = canvas.selectAll('[class*="xGrid-"]');

  if (!xGridLines.empty()) {
    xGridLines.remove();
  }

  var yGridLines = canvas.selectAll('[class*="yGrid-"]');

  if (!yGridLines.empty()) {
    yGridLines.remove();
  }
};