import { x11Colors } from './x11Colors'; // import * as x11Colors from './x11Colors.json'

import isString from 'lodash/isString';
/**
 * Array of X11 (HTML) color objects
 * @type {string[]}
 */

var validColors = x11Colors.map(function (c) {
  return c.name.toLowerCase();
});
/**
 * Return true if color string is a valid hex string or X11 (HTML) Color name
 * @param {string} color
 * @returns {boolean}
 */

export var isValidColor = function isValidColor(color) {
  if (!isString(color) || !color.length) {
    return false;
  }

  if (color.charAt(0) === '#') {
    var hex = color.substr(1);

    if (hex.length <= 6 && !isNaN(parseInt(hex, 16))) {
      return true;
    }
  } else {
    color = color.toLowerCase();

    if (validColors.includes(color)) {
      return true;
    }
  }

  return false;
};