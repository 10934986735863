import React, { Component } from 'react';
import './App.css';
import * as Icon from 'react-feather';
import ReactTooltip from 'react-tooltip';
import QuickGraphPanel from './QuickGraphPanel';

const buttonStyle = {
  cursor: 'pointer',
  padding: '0.1em 0.5em',
  fontSize: '1em'
};

const docsUrl = 'https://d3quickgraph.stefan00.com/docs/class/src/index.js~D3QuickGraph.html';

/**
 * This application is a React wrapper for the NPM package d3quickgraph by Stefan Musarra.
 *
 * Clicking on the settings (gear) icon in the upper right corner displays the "GraphPanel"
 * to allow changing line options for the data line and the grid.
 *
 * The main app has 2 event handlers:
 *   1.  clicking the Settings icon will toggle the GraphOptions display state
 *   2.  clicking the Apply icon will apply the new GraphOptions to the graph and then
 *       hide the display state
 */
class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showSettings: false
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  handleShow (evt) {
    console.log('handleShow');
    evt.preventDefault();
    this.setState( prevState => ( { showSettings: !prevState.showSettings } ),
      this._graphPanel.handleShow
    );
  }

  handleApply (evt) {
    console.log('handleApply');
    evt.preventDefault();
    const handleBoth = () => {
      this._graphPanel.handleApply();
      this._graphPanel.handleShow();
    };
    this.setState( prevState => ( { showSettings: !prevState.showSettings } ),
      handleBoth
    );
  }

  render() {
    const { handleShow, handleApply } = this;
    const headerStyle = {
      backgroundColor: '#282c34',
      color: 'white',
      padding: '0.5em 0'
    };
    const spanStyle = {
      fontSize: '1.5em'
    };
    const settingsStyle = {
      marginRight: '1em',
      display: 'inline'
    };
    const applyStyle = {
      marginRight: '1em',
      display: 'inline'
    };
    const rightIconsStyle = {
      display: 'inline',
      position: 'absolute',
      top: '1em',
      right: '1em'
    };
    const leftIconsStyle = {
      display: 'inline',
      position: 'absolute',
      top: '1em',
      left: '1em'
    };
    const settingsTitle = 'Click to toggle settings panel';
    const applyTitle = 'Click to apply settings to graph';
    const settingsForm = (
      <form onSubmit={ handleShow } action="#" style={ settingsStyle }>
        <button type="submit" data-tip data-for="settings-tip">
          <Icon.Settings />
        </button>
        <ReactTooltip id="settings-tip" className="app-tooltip" type="warning">
          <span>{ settingsTitle }</span>
        </ReactTooltip>
      </form>
    );
    const applyForm = this.state.showSettings && (
      <form onSubmit={ handleApply } action="#" style={ applyStyle }>
        <button style={ buttonStyle } data-tip data-for="apply-tip" data-type="success" data-place="bottom" type="submit" className="btn btn-success" >Apply</button>
        <ReactTooltip id="apply-tip" className="app-tooltip">
          <span>{ applyTitle }</span>
        </ReactTooltip>
      </form>
    );
    const helpForm = (
      <div style={ settingsStyle }>
        <button data-tip data-for="help-tip" className="help-tip">
          <Icon.HelpCircle/>
        </button>
        <ReactTooltip id="help-tip">
          <div style={{
            backgroundColor: '#1874CD',
            color: '#fff',
            position: 'absolute',
            fontSize: '1.2em',
            width: '300px',
            top: '20px',
            left: '20px',
            padding: '0.5em',
            textAlign: 'left'
          }}>
            <ul>
              <li>d3QuickGraph is a free node module written by Stefan Musarra.</li>
              <li>It is designed for quickly adding graphs to your web pages.</li>
              <li>This React program demonstrates some of the many options in d3QuickGraph.</li>
              <li>Click the Gear icon to view the settings panel.</li>
              <li>Click the Info icon to view the JSDOCs for d3QuickGrph</li>
            </ul>
          </div>
        </ReactTooltip>
      </div>
    );
    const infoForm = (
      <a target="_blank" rel="noopener noreferrer" href={docsUrl}>
        <button style={ settingsStyle } data-tip data-for="info-tip">
          <Icon.Info/>
        </button>

        <ReactTooltip id="info-tip">
          <div style={{
            backgroundColor: '#CD69C9',
            color: '#fff',
            fontSize: '1.2em',
            padding: '0.3em 0.5em'
          }}>
            Click to view JSDOCs for d3QuickGraph
          </div>
        </ReactTooltip>
      </a>
    );
    return (
      <div className="App">
        <div style={ headerStyle }>
          <div style={ leftIconsStyle }>
            { helpForm }
            { infoForm }
          </div>
          <span style={ spanStyle }>d3quickgraph-react</span>
          <div style={ rightIconsStyle }>
            { settingsForm }
            { applyForm }
          </div>
        </div>
        <QuickGraphPanel ref={(c) => this._graphPanel = c}  />
      </div>
    );
  }
}

export default App;
