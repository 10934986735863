/**
 * Array of 140 Standard X11 HTML Color names, hex string and rgb values.
 *
 * @type {*[Object<name: string: hex: string: rgb: 'string'>]}
 */
export var x11Colors = [{
  'name': 'Pink',
  'hex': 'ffc0cb',
  'rgb': '255,192,203'
}, {
  'name': 'LightPink',
  'hex': 'ffb6c1',
  'rgb': '255,182,193'
}, {
  'name': 'HotPink',
  'hex': 'ff69b4',
  'rgb': '255,105,180'
}, {
  'name': 'DeepPink',
  'hex': 'ff1493',
  'rgb': '255,20,147'
}, {
  'name': 'PaleVioletRed',
  'hex': 'db7093',
  'rgb': '219,112,147'
}, {
  'name': 'MediumVioletRed',
  'hex': 'c71585',
  'rgb': '199,21,133'
}, {
  'name': 'LightSalmon',
  'hex': 'ffa07a',
  'rgb': '255,160,122'
}, {
  'name': 'Salmon',
  'hex': 'fa8072',
  'rgb': '250,128,114'
}, {
  'name': 'DarkSalmon',
  'hex': 'e9967a',
  'rgb': '233,150,122'
}, {
  'name': 'LightCoral',
  'hex': 'f08080',
  'rgb': '240,128,128'
}, {
  'name': 'IndianRed',
  'hex': 'cd5c5c',
  'rgb': '205,92,92'
}, {
  'name': 'Crimson',
  'hex': 'dc143c',
  'rgb': '220,20,60'
}, {
  'name': 'FireBrick',
  'hex': 'b22222',
  'rgb': '178,34,34'
}, {
  'name': 'DarkRed',
  'hex': '8b0000',
  'rgb': '139,0,0'
}, {
  'name': 'Red',
  'hex': 'ff0000',
  'rgb': '255,0,0'
}, {
  'name': 'OrangeRed',
  'hex': 'ff4500',
  'rgb': '255,69,0'
}, {
  'name': 'Tomato',
  'hex': 'ff6347',
  'rgb': '255,99,71'
}, {
  'name': 'Coral',
  'hex': 'ff7f50',
  'rgb': '255,127,80'
}, {
  'name': 'DarkOrange',
  'hex': 'ff8c00',
  'rgb': '255,140,0'
}, {
  'name': 'Orange',
  'hex': 'ffa500',
  'rgb': '255,165,0'
}, {
  'name': 'Gold',
  'hex': 'ffd700',
  'rgb': '255,215,0'
}, {
  'name': 'Yellow',
  'hex': 'ffff00',
  'rgb': '255,255,0'
}, {
  'name': 'LightYellow',
  'hex': 'ffffe0',
  'rgb': '255,255,224'
}, {
  'name': 'LemonChiffon',
  'hex': 'fffacd',
  'rgb': '255,250,205'
}, {
  'name': 'LightGoldenrodYellow',
  'hex': 'fafad2',
  'rgb': '250,250,210'
}, {
  'name': 'PapayaWhip',
  'hex': 'ffefd5',
  'rgb': '255,239,213'
}, {
  'name': 'Moccasin',
  'hex': 'ffe4b5',
  'rgb': '255,228,181'
}, {
  'name': 'PeachPuff',
  'hex': 'ffdab9',
  'rgb': '255,218,185'
}, {
  'name': 'PaleGoldenrod',
  'hex': 'eee8aa',
  'rgb': '238,232,170'
}, {
  'name': 'Khaki',
  'hex': 'f0e68c',
  'rgb': '240,230,140'
}, {
  'name': 'DarkKhaki',
  'hex': 'bdb76b',
  'rgb': '189,183,107'
}, {
  'name': 'Cornsilk',
  'hex': 'fff8dc',
  'rgb': '255,248,220'
}, {
  'name': 'BlanchedAlmond',
  'hex': 'ffebcd',
  'rgb': '255,235,205'
}, {
  'name': 'Bisque',
  'hex': 'ffe4c4',
  'rgb': '255,228,196'
}, {
  'name': 'NavajoWhite',
  'hex': 'ffdead',
  'rgb': '255,222,173'
}, {
  'name': 'Wheat',
  'hex': 'f5deb3',
  'rgb': '245,222,179'
}, {
  'name': 'BurlyWood',
  'hex': 'deb887',
  'rgb': '222,184,135'
}, {
  'name': 'Tan',
  'hex': 'd2b48c',
  'rgb': '210,180,140'
}, {
  'name': 'RosyBrown',
  'hex': 'bc8f8f',
  'rgb': '188,143,143'
}, {
  'name': 'SandyBrown',
  'hex': 'f4a460',
  'rgb': '244,164,96'
}, {
  'name': 'Goldenrod',
  'hex': 'daa520',
  'rgb': '218,165,32'
}, {
  'name': 'DarkGoldenrod',
  'hex': 'b8860b',
  'rgb': '184,134,11'
}, {
  'name': 'Peru',
  'hex': 'cd853f',
  'rgb': '205,133,63'
}, {
  'name': 'Chocolate',
  'hex': 'd2691e',
  'rgb': '210,105,30'
}, {
  'name': 'SaddleBrown',
  'hex': '8b4513',
  'rgb': '139,69,19'
}, {
  'name': 'Sienna',
  'hex': 'a0522d',
  'rgb': '160,82,45'
}, {
  'name': 'Brown',
  'hex': 'a52a2a',
  'rgb': '165,42,42'
}, {
  'name': 'Maroon',
  'hex': '800000',
  'rgb': '128,0,0'
}, {
  'name': 'DarkOliveGreen',
  'hex': '556b2f',
  'rgb': ',85,107,47'
}, {
  'name': 'Olive',
  'hex': '808000',
  'rgb': '128,128,0'
}, {
  'name': 'OliveDrab',
  'hex': '6b8e23',
  'rgb': '107,142,35'
}, {
  'name': 'YellowGreen',
  'hex': '9acd32',
  'rgb': '154,205,50'
}, {
  'name': 'LimeGreen',
  'hex': '32cd32',
  'rgb': ',50,205,50'
}, {
  'name': 'Lime',
  'hex': '00ff00',
  'rgb': ',0,255,0'
}, {
  'name': 'LawnGreen',
  'hex': '7cfc00',
  'rgb': '124,252,0'
}, {
  'name': 'Chartreuse',
  'hex': '7fff00',
  'rgb': '127,255,0'
}, {
  'name': 'GreenYellow',
  'hex': 'adff2f',
  'rgb': '173,255,47'
}, {
  'name': 'SpringGreen',
  'hex': '00ff7f',
  'rgb': ',0,255,127'
}, {
  'name': 'MediumSpringGreen',
  'hex': '00fa9a',
  'rgb': ',0,250,154'
}, {
  'name': 'LightGreen',
  'hex': '90ee90',
  'rgb': '144,238,144'
}, {
  'name': 'PaleGreen',
  'hex': '98fb98',
  'rgb': '152,251,152'
}, {
  'name': 'DarkSeaGreen',
  'hex': '8fbc8f',
  'rgb': '143,188,143'
}, {
  'name': 'MediumSeaGreen',
  'hex': '3cb371',
  'rgb': ',60,179,113'
}, {
  'name': 'SeaGreen',
  'hex': '2e8b57',
  'rgb': ',46,139,87'
}, {
  'name': 'ForestGreen',
  'hex': '228b22',
  'rgb': ',34,139,34'
}, {
  'name': 'Green',
  'hex': '008000',
  'rgb': ',0,128,0'
}, {
  'name': 'DarkGreen',
  'hex': '006400',
  'rgb': ',0,100,0'
}, {
  'name': 'MediumAquamarine',
  'hex': '66cdaa',
  'rgb': '102,205,170'
}, {
  'name': 'Aqua',
  'hex': '00ffff',
  'rgb': ',0,255,255'
}, {
  'name': 'Cyan',
  'hex': '00ffff',
  'rgb': ',0,255,255'
}, {
  'name': 'LightCyan',
  'hex': 'e0ffff',
  'rgb': '224,255,255'
}, {
  'name': 'PaleTurquoise',
  'hex': 'afeeee',
  'rgb': '175,238,238'
}, {
  'name': 'Aquamarine',
  'hex': '7fffd4',
  'rgb': '127,255,212'
}, {
  'name': 'Turquoise',
  'hex': '40e0d0',
  'rgb': ',64,224,208'
}, {
  'name': 'MediumTurquoise',
  'hex': '48d1cc',
  'rgb': ',72,209,204'
}, {
  'name': 'DarkTurquoise',
  'hex': '00ced1',
  'rgb': ',0,206,209'
}, {
  'name': 'LightSeaGreen',
  'hex': '20b2aa',
  'rgb': ',32,178,170'
}, {
  'name': 'CadetBlue',
  'hex': '5f9ea0',
  'rgb': ',95,158,160'
}, {
  'name': 'DarkCyan',
  'hex': '008b8b',
  'rgb': ',0,139,139'
}, {
  'name': 'Teal',
  'hex': '008080',
  'rgb': ',0,128,128'
}, {
  'name': 'LightSteelBlue',
  'hex': 'b0c4de',
  'rgb': '176,196,222'
}, {
  'name': 'PowderBlue',
  'hex': 'b0e0e6',
  'rgb': '176,224,230'
}, {
  'name': 'LightBlue',
  'hex': 'add8e6',
  'rgb': '173,216,230'
}, {
  'name': 'SkyBlue',
  'hex': '87ceeb',
  'rgb': '135,206,235'
}, {
  'name': 'LightSkyBlue',
  'hex': '87cefa',
  'rgb': '135,206,250'
}, {
  'name': 'DeepSkyBlue',
  'hex': '00bfff',
  'rgb': ',0,191,255'
}, {
  'name': 'DodgerBlue',
  'hex': '1e90ff',
  'rgb': ',30,144,255'
}, {
  'name': 'CornflowerBlue',
  'hex': '6495ed',
  'rgb': '100,149,237'
}, {
  'name': 'SteelBlue',
  'hex': '4682b4',
  'rgb': ',70,130,180'
}, {
  'name': 'RoyalBlue',
  'hex': '4169e1',
  'rgb': ',65,105,225'
}, {
  'name': 'Blue',
  'hex': '0000ff',
  'rgb': ',0,0,255'
}, {
  'name': 'MediumBlue',
  'hex': '0000cd',
  'rgb': ',0,0,205'
}, {
  'name': 'DarkBlue',
  'hex': '00008b',
  'rgb': ',0,0,139'
}, {
  'name': 'Navy',
  'hex': '000080',
  'rgb': ',0,0,128'
}, {
  'name': 'MidnightBlue',
  'hex': '191970',
  'rgb': ',25,25,112'
}, {
  'name': 'Lavender',
  'hex': 'e6e6fa',
  'rgb': '230,230,250'
}, {
  'name': 'Thistle',
  'hex': 'd8bfd8',
  'rgb': '216,191,216'
}, {
  'name': 'Plum',
  'hex': 'dda0dd',
  'rgb': '221,160,221'
}, {
  'name': 'Violet',
  'hex': 'ee82ee',
  'rgb': '238,130,238'
}, {
  'name': 'Orchid',
  'hex': 'da70d6',
  'rgb': '218,112,214'
}, {
  'name': 'Fuchsia',
  'hex': 'ff00ff',
  'rgb': '255,0,255'
}, {
  'name': 'Magenta',
  'hex': 'ff00ff',
  'rgb': '255,0,255'
}, {
  'name': 'MediumOrchid',
  'hex': 'ba55d3',
  'rgb': '186,85,211'
}, {
  'name': 'MediumPurple',
  'hex': '9370db',
  'rgb': '147,112,219'
}, {
  'name': 'BlueViolet',
  'hex': '8a2be2',
  'rgb': '138,43,226'
}, {
  'name': 'DarkViolet',
  'hex': '9400d3',
  'rgb': '148,0,211'
}, {
  'name': 'DarkOrchid',
  'hex': '9932cc',
  'rgb': '153,50,204'
}, {
  'name': 'DarkMagenta',
  'hex': '8b008b',
  'rgb': '139,0,139'
}, {
  'name': 'Purple',
  'hex': '800080',
  'rgb': '128,0,128'
}, {
  'name': 'Indigo',
  'hex': '4b0082',
  'rgb': ',75,0,130'
}, {
  'name': 'DarkSlateBlue',
  'hex': '483d8b',
  'rgb': ',72,61,139'
}, {
  'name': 'SlateBlue',
  'hex': '6a5acd',
  'rgb': '106,90,205'
}, {
  'name': 'MediumSlateBlue',
  'hex': '7b68ee',
  'rgb': '123,104,238'
}, {
  'name': 'White',
  'hex': 'ffffff',
  'rgb': '255,255,255'
}, {
  'name': 'Snow',
  'hex': 'fffafa',
  'rgb': '255,250,250'
}, {
  'name': 'Honeydew',
  'hex': 'f0fff0',
  'rgb': '240,255,240'
}, {
  'name': 'MintCream',
  'hex': 'f5fffa',
  'rgb': '245,255,250'
}, {
  'name': 'Azure',
  'hex': 'f0ffff',
  'rgb': '240,255,255'
}, {
  'name': 'AliceBlue',
  'hex': 'f0f8ff',
  'rgb': '240,248,255'
}, {
  'name': 'GhostWhite',
  'hex': 'f8f8ff',
  'rgb': '248,248,255'
}, {
  'name': 'WhiteSmoke',
  'hex': 'f5f5f5',
  'rgb': '245,245,245'
}, {
  'name': 'Seashell',
  'hex': 'fff5ee',
  'rgb': '255,245,238'
}, {
  'name': 'Beige',
  'hex': 'f5f5dc',
  'rgb': '245,245,220'
}, {
  'name': 'OldLace',
  'hex': 'fdf5e6',
  'rgb': '253,245,230'
}, {
  'name': 'FloralWhite',
  'hex': 'fffaf0',
  'rgb': '255,250,240'
}, {
  'name': 'Ivory',
  'hex': 'fffff0',
  'rgb': '255,255,240'
}, {
  'name': 'AntiqueWhite',
  'hex': 'faebd7',
  'rgb': '250,235,215'
}, {
  'name': 'Linen',
  'hex': 'faf0e6',
  'rgb': '250,240,230'
}, {
  'name': 'LavenderBlush',
  'hex': 'fff0f5',
  'rgb': '255,240,245'
}, {
  'name': 'MistyRose',
  'hex': 'ffe4e1',
  'rgb': '255,228,225'
}, {
  'name': 'Gainsboro',
  'hex': 'dcdcdc',
  'rgb': '220,220,220'
}, {
  'name': 'LightGray',
  'hex': 'd3d3d3',
  'rgb': '211,211,211'
}, {
  'name': 'Silver',
  'hex': 'c0c0c0',
  'rgb': '192,192,192'
}, {
  'name': 'DarkGrey',
  'hex': 'a9a9a9',
  'rgb': '169,169,169'
}, {
  'name': 'Grey',
  'hex': '808080',
  'rgb': '128,128,128'
}, {
  'name': 'DimGrey',
  'hex': '696969',
  'rgb': '105,105,105'
}, {
  'name': 'LightSlateGrey',
  'hex': '778899',
  'rgb': '119,136,153'
}, {
  'name': 'SlateGrey',
  'hex': '708090',
  'rgb': '112,128,144'
}, {
  'name': 'DarkSlateGrey',
  'hex': '2f4f4f',
  'rgb': ',47,79,79'
}, {
  'name': 'Black',
  'hex': '000000',
  'rgb': ',0,0,0'
}];