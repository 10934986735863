import D3QuickGraph from 'd3quickgraph';
import React, {Component} from 'react';
import isEqual from 'lodash/isEqual';

/**
 * This component contains 2 empty divs for creating a d3quickgraph:
 *   1.  The main container, which is designed to allow optional title and figure captions.
 *   2.  The child graph container, where the actual d3 graph is drawn.
 */
export default class QuickGraph extends Component {

  constructor () {
    super();
    this.state = {
      d3QuickGraph: null
    };
  }

  componentDidMount () {
    console.log('QuickGraph.DidMount');
    this.drawGraph(this.props);
  }

  shouldComponentUpdate (nextProps ) {
    const status = !isEqual(nextProps, this.props);
    console.log('QuickGraph.shouldComponentUpdate', status);
    if (status) {
      this.drawGraph(nextProps);
    }
    return status;
  }

  componentDidUpdate () {
    console.log('QuickGraph.componentDidUpdate');
  }

  drawGraph (props) {
    const { gridOptions, lineOptions } = props;
    const options = {
      mainContainer: this._mainContainer,
      graphContainer: this._graphContainer,
      widthPercent: 50,
      grid: gridOptions
    };
    if (options.widthPercent) {
      this._mainContainer.style.marginLeft = (100 - options.widthPercent)/2 + '%';
    }
    let d3QuickGraph = this.state.d3QuickGraph;
    if (!d3QuickGraph) {
      console.log('drawGraph: create new graph');
      d3QuickGraph = new D3QuickGraph(options);
      this.setState({
        d3QuickGraph
      });
      this.dataLine = this.getDataLine();
      d3QuickGraph
        .addDataLine(this.dataLine);
    } else {
      console.log('drawGraph: update options', options);
      if (this.dataLine) {
        this.dataLine = Object.assign(this.dataLine, lineOptions);
      }
      d3QuickGraph.updateOptions(options);
    }
    d3QuickGraph
      .draw();
  }

  getDataLine () {
    const name = 'Gaussian';
    const width = 5;
    const n = 41;
    const xMin = -2;
    const xMax = 2;
    const f = (x) => Math.exp(x * x / -2);
    const data = new Array(n)
      .fill(null)
      .map((v, i) => {
        const x = parseFloat((xMin + (xMax - xMin) * i / (n - 1)).toPrecision(8));
        return {
          x,
          y: f(x)
        };
      });
    return {
      name,
      data,
      width
    };
  }

  render () {
    return (
      <div className="main-container" ref={(c) => this._mainContainer = c} >
        <div className="graph-container" ref={(c) => this._graphContainer = c} ></div>
      </div>
    );
  }
}