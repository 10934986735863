import _objectSpread from "/Users/stefan/www/pluralsight/d3quickgraph-react/node_modules/@babel/runtime/helpers/esm/objectSpread";

/**
 * * LineObject is a data structure for storing a line name and array of data points
 * * All properties are public
 * * Functions using LineObject call checkLineObject to ensure it contains valid values
 * @typedef {Object} LineObject
 * @property {string} name key for accessing the Line Object
 * @property {Array} data each item in the array is a data point {x: <number>, y: <number>}
 * @property {number} [width] Width of line (default value = 1)
 * @property {string} [color] Valid hex string or X11 (CSS) Color (default value = 'black')
 * @property {Array<number>} [dashArray] alternating pixel length of dash and spacing
 */

/**
 * * PointObject is a data structure for storing a point name and array of data points
 * * All properties are public
 * * Functions using PointObject call checkPointObject to ensure it contains valid values
 * * If optional properties are not supplied, the value from the parent PointGroupObject will be used
 * @typedef {Object} PointObject
 * @property {string} name key for accessing the Point Object
 * @property {number} x x-coordinate
 * @property {number} y y-coordinate
 * @property {string} [shape] shape (default value = 'circle')
 * @property {number} [r] radius (in px) if shape is a circle (default value = 10)
 * @property {string} [fill] Valid hex string or X11 (CSS) Color (default value = 'red')
 * @property {string} [stroke] Valid hex string or X11 (CSS) Color (default value = 'black')
 */

/**
 * * PointGroupObject is a data structure for storing a point group name and array of pointObjects
 * * All properties are public
 * * Functions using PointGroupObject call checkPointGroupObject to ensure it contains valid values
 * @typedef {Object} PointGroupObject
 * @property {string} name key for accessing the Point Group Object
 * @property {Array<PointObject>} data each item in the array is a PointObject
 * @property {string} [shape] shape (default value = 'circle')
 * @property {number} [r] radius (in px) if shape is a circle (default value = 10)
 * @property {string} [fill] Valid hex string or X11 (CSS) Color (default value = 'red')
 * @property {string} [stroke] Valid hex string or X11 (CSS) Color (default value = 'black')
 */
import { defaultOptions } from './default-options.js';
import * as $ from 'jquery';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import isInteger from 'lodash/isInteger';
import isString from 'lodash/isString';
import cloneDeep from 'lodash/cloneDeep';
import { isValidColor } from './colors';
/**
 * Array of allowable shape strings
 * Currently, only "circle" is implemented
 * Future shapes will include:
 *   ['circle', 'ellipse', 'square', 'rect', 'triangle', 'poly']
 *
 * @type {Shapes}
 */

var shapes = ['circle'];
/**
 * Regular expression of valid name characters
 * @type {RegExp}
 */

var nameRe = /[^a-zA-Z0-9_.-]/;
/**
 * Array of strings for each side
 * @type {string[]}
 */

export var sides = ['bottom', 'top', 'right', 'left'];
/**
 * Return true if $element is a non-empty jQuery instance
 * @public
 * @function
 * @param {object} $element object being checked
 * @returns {boolean}
 */

export var isJQuery = function isJQuery($element) {
  return typeof $element === 'object' && $element.length && $element[0] instanceof window.HTMLElement;
};
/**
 * Return true if element is an HTMLElement
 * @public
 * @function
 * @param {object} element
 * @returns {boolean}
 */

export var isHtmlElement = function isHtmlElement(element) {
  return isObject(element) && element instanceof window.HTMLElement;
};
/**
 * Return true if x is a positive number
 * @public
 * @function
 * @param {number} x number being checked
 * @returns {boolean}
 */

export var isPositiveNumber = function isPositiveNumber(x) {
  return isNumber(x) && x > 0;
};
/**
 * Return true if x is a non-negative integer
 * @public
 * @function
 * @param {number} x number being checked
 * @returns {boolean}
 */

export var isNonNegativeInteger = function isNonNegativeInteger(x) {
  return isInteger(x) && x >= 0;
};
/**
 * Duck-typing for d3.axis function
 *
 * @param x
 * @returns {boolean}
 */

export var isAxis = function isAxis(x) {
  return typeof x === 'function' && x.name === 'axis' && typeof x.ticks === 'function';
};
/**
 * Return true if side is one of 'top', 'right', 'bottom', or 'left'
 * @param x
 * @returns {boolean}
 */

export var isValidSide = function isValidSide(x) {
  return sides.includes(x);
};
/**
 * Checks that o is an object
 * @public
 * @function
 * @param {object} o object being checked
 * @param {string} name name of the function that is checking the object
 * @returns {boolean}
 * @throws If o is not an object, throw an error showing the name of the function
 */

export var checkObject = function checkObject(o, name) {
  if (!isObject(o)) {
    throw new Error("".concat(name, ": options is not an object"));
  }

  return true;
};
/**
 * Data structure for margin
 * @typedef {object} Margin
 * @property {number} top number of pixels on top margin
 * @property {number} bottom number of pixels on bottom margin
 * @property {number} left number of pixels on left margin
 * @property {number} right number of pixels on right margin
 */

/**
 * Data structure for padding
 * @typedef {object} Padding
 * @property {number} top number of pixels on top padding
 * @property {number} bottom number of pixels on bottom padding
 * @property {number} left number of pixels on left padding
 * @property {number} right number of pixels on right padding
 */

/**
 * Data structure for computing each axes bounds
 * @typedef {object} AxisRounding
 * @property {number} [value] if value is set, this exact value is used and there is no rounding
 * @property {number} [digits] number of significant digits used for rounding
 * @property {boolean} [up] if true, then round up, else down. The default is to round down for xMin and yMin,
 * and round up for xMax and yMax
 */

/**
 * Data structure each axes AxisRounding Object
 * @typeDef {object} GraphAxisRounding
 * @property {AxisRounding} xMin AxisRounding Object for minimum of X axis
 * @property {AxisRounding} xMax AxisRounding Object for maximum of X axis
 * @property {AxisRounding} yMin AxisRounding Object for minimum of Y axis
 * @property {AxisRounding} yMax AxisRounding Object for maximum of Y axis
 */

/**
 * * Data structure for grid options
 * * If dashArray is undefined or null, then a solid line is drawn
 * @typedef {object} GridOptions
 * @property {number} [width] Width of grid
 * @property {string} [color] Valid hex string or X11 (CSS) Color
 * @property {Array<number>} [dashArray] alternating pixel length of dash and spacing
 */

/**
 * * Data structure for tick options.  Each TickOptions object must be keyed by a valid side
 * * @see https://github.com/d3/d3-axis
 * @typedef {object} TickOptions
 * @property {number|boolean} numTicks if true, the default number is used, if a number, it
 *   is used by d3 as a suggested/approximate number of ticks that will give a rounded value
 * @property {string} [format] format string @see https://github.com/d3/d3-format
 *   [ fill ][ align ][ sign ][ symbol ][ 0 ][ width ][ , ][ .precision ][ ~ ][ type ]
 * @property {Array<number>} [values] Exact tick values to use
 * @property {Array<number>} [size] (px)
 * @property {Array<number>} [sizeInner] (px)
 * @property {Array<number>} [sizeOuter] (px)
 * @property {Array<number>} [sizePadding] (px)
 */

/**
 * * Data structure for line options
 * @typedef {object} LineOptions
 * @property {number} [width] Width of grid
 * @property {string} [color] Valid hex string or X11 (CSS) Color
 * @property {Array<number>} [dashArray] alternating pixel length of dash and spacing
 */

/**
 * * Data structure for axis options
 * * If dashArray is undefined or null, then a solid line is drawn
 * @typedef {object} AxisOptions
 * @property {LineOptions?} [top] Axis line options for top
 * @property {LineOptions?} [bottom] Axis line options for bottom
 * @property {LineOptions?} [left] Axis line options for left
 * @property {LineOptions?} [right] Axis line options for right
 */

/**
 * * Data structure for ticks options
 * * If ticks is undefined or null, then d3 chooses automatic ticks
 * @typedef {object} TickOptions
 * @property {number?} [bottom] If specified, exact number of ticks on bottom axis
 * @property {number?} [top] If specified, exact number of ticks on top axis
 * @property {number?} [right] If specified, exact number of ticks on right axis
 * @property {number?} [left] If specified, exact number of ticks on left axis
 */

/**
 * @typedef {object} GraphOptions
 * @property {jQuery?} $mainContainer either $mainContainer or mainContainer must be provided
 * @property {HTMLElement?} mainContainer Outer div that wraps the graph container
 * @property {jQuery?} $graphContainer either $graphContainer or graphContainer must be provided
 * @property {HTMLElement?} graphContainer Inner div where the svg element for the graph will be appended
 * @property {Margin} [margin] margin on $graphContainer
 * @property {Padding} [padding] padding $graphContainer between edge of Svg and graph axes
 * @property {GraphAxisRounding} [axisRounding]
 * @property {AxisOptions} [axis] if undefined or null, then default line options are used
 * @property {GridOptions} [grid] if undefined or null, then no grid is drawn
 * @property {TickOptions} [ticks] if undefined or null, then d3's automatic tick spacing is used
 */

/**
 * * Check that options has required properties of a GraphOptions data structure
 * * Non-required properties are set to default values if they are not supplied
 * * Returns a deep clone of the input GraphOptions object
 * * This method can be used to check that GraphOptions object is valid before calling the D3QuickGraph constructor
 * @public
 * @function
 * @param {GraphOptions} options
 * @returns {GraphOptions}
 * @throws If options contains invalid properties
 */

export var checkOptions = function checkOptions(options) {
  checkObject(options, 'checkOptions');
  options = cloneDeep(options);

  if (!isJQuery(options.$mainContainer)) {
    if (isHtmlElement(options.mainContainer)) {
      options.$mainContainer = $(options.mainContainer);
    }

    if (!isJQuery(options.$mainContainer)) {
      throw new Error('$mainContainer must be of type jQuery');
    }
  }

  options.mainContainer = options.$mainContainer[0];

  if (!isJQuery(options.$graphContainer)) {
    if (isHtmlElement(options.graphContainer)) {
      options.$graphContainer = $(options.graphContainer);
    }

    if (!isJQuery(options.$graphContainer)) {
      throw new Error('$graphContainer must be of type jQuery');
    }
  }

  options.graphContainer = options.$graphContainer[0];

  if (!options.mainContainer.contains(options.graphContainer)) {
    throw new Error('graphContainer must be a descendent of the mainContainer');
  }

  if (!isObject(options.margin)) {
    options.margin = _objectSpread({}, defaultOptions.margin);
  } else {
    options.margin = _objectSpread({}, defaultOptions.margin, options.margin);
  }

  if (!isObject(options.padding)) {
    options.padding = _objectSpread({}, defaultOptions.padding);
  } else {
    options.padding = _objectSpread({}, defaultOptions.padding, options.padding);
  }

  if (isPositiveNumber(options.widthPercent)) {
    options.widthPercent = Math.min(options.widthPercent, 100);
  }

  options.maxWidth = isPositiveNumber(options.maxWidth) ? options.maxWidth : defaultOptions.maxWidth;
  options.aspectRatio = isPositiveNumber(options.aspectRatio) ? options.aspectRatio : defaultOptions.aspectRatio;
  options.graphWidth = options.maxWidth;
  options.graphHeight = options.graphWidth / options.aspectRatio;
  options.svgWidth = options.graphWidth - options.margin.left - options.margin.right - options.padding.left - options.padding.right;
  options.svgHeight = options.graphHeight - options.margin.top - options.margin.bottom - options.padding.top - options.padding.bottom;

  if (!isObject(options.axisRounding)) {
    options.axisRounding = defaultOptions.axisRounding;
  } else {
    ['xMin', 'xMax', 'yMin', 'yMax'].forEach(function (key) {
      /*
       * if axisRounding[key] = false, there will be no rounding
       * if axisRounding[key] = null, the default valuies will be used
       * if axisRounding[key].value is a number, it fixes the axis to that value
       */
      if (isObject(options.axisRounding[key])) {
        if (!isNumber(options.axisRounding[key].value)) {
          if (!isBoolean(options.axisRounding[key].up)) {
            options.axisRounding[key].up = defaultOptions.axisRouding[key].up;
          }

          if (!isNonNegativeInteger(options.axisRounding[key].digits)) {
            options.axisRounding[key].digits = defaultOptions.axisRouding[key].digits;
          }
        }
      } else if (options.axisRounding[key] !== false) {
        options.axisRounding[key] = _objectSpread({}, defaultOptions.axisRounding[key]);
      } // } else {
      //   options.axisRounding[key] = null
      // }

    });
  }

  if (options.grid !== false) {
    if (!isObject(options.grid)) {
      options.grid = defaultOptions.grid;
    } else {
      if (!isNumber(options.grid.width)) {
        options.grid.width = defaultOptions.grid.width;
      }

      if (!isString(options.grid.color)) {
        options.grid.color = defaultOptions.grid.color;
      }

      if (options.grid.dashArray !== false) {
        if (!Array.isArray(options.grid.dashArray)) {
          options.grid.dashArray = defaultOptions.grid.dashArray;
        }
      }

      checkLineOptions(options.grid, 'grid');
    }
  }

  if (isObject(options.axis)) {
    sides.forEach(function (side) {
      if (isObject(options.axis[side])) {
        checkLineOptions(options.axis[side], side);
      }
    });
  } else {
    options.axis = {};
  }

  if (!isBoolean(options.ticks)) {
    if (!isObject(options.ticks)) options.ticks = true;
  }

  if (isObject(options.ticks)) {
    sides.forEach(function (side) {
      var sideTicks = options.ticks[side];

      if (!isNumber(sideTicks) && !isObject(sideTicks) && !isBoolean(sideTicks)) {
        options.ticks[side] = true;
      } else if (isNumber(sideTicks)) {
        options.ticks[side] = {
          number: sideTicks
        };
      } else {
        if (sideTicks.values && !Array.isArray(sideTicks.values)) {
          console.log("options.ticks.".concat(side, ".values must be an array"));
          delete sideTicks.values;
        }

        if (sideTicks.format && !isString(sideTicks.format)) {
          console.log("options.ticks.".concat(side, ".format must be a string"));
          delete sideTicks.format;
        }

        if (sideTicks.size && !isNumber(sideTicks.size)) {
          console.log("options.ticks.".concat(side, ".size must be a number"));
          delete sideTicks.size;
        }

        if (sideTicks.sizeInner && !isNumber(sideTicks.sizeInner)) {
          console.log("options.ticks.".concat(side, ".sizeInner must be a number"));
          delete sideTicks.sizeInner;
        }

        if (sideTicks.sizeOuter && !isNumber(sideTicks.sizeOuter)) {
          console.log("options.ticks.".concat(side, ".sizeOuter must be a number"));
          delete sideTicks.sizeOuter;
        }
      }
    });
  }

  return options;
};
/**
 * * Checks that lineOptions are valid
 * * may mutate properties color, width and dashArray if they are invalid
 * * This method can be used to check if lineOptions are valid before calling the addDataLine method
 * @public
 * @param {LineOptions} lineOptions
 * @param {string?} name optional string for displaying in error statements
 * @returns {LineOptions}
 * @throws If lineOptions contains invalid properties
 */

export var checkLineOptions = function checkLineOptions(lineOptions, name) {
  if (!isString(name)) name = '';

  if (isString(lineOptions.color)) {
    if (!isValidColor(lineOptions.color)) {
      throw new Error("lineOptions \"".concat(name, "\" color \"").concat(lineOptions.color, "\" is not a valid color"));
    }
  } else {
    lineOptions.color = defaultOptions.line.color;
  }

  if (isNumber(lineOptions.width)) {
    if (!isNonNegativeInteger(lineOptions.width)) {
      throw new Error("lineOptions \"".concat(name, "\" width ").concat(lineOptions.width, " is not an integer"));
    }
  } else {
    lineOptions.width = defaultOptions.line.width;
  }

  if (Array.isArray(lineOptions.dashArray)) {
    if (!lineOptions.dashArray.length) {
      lineOptions.dashArray = null;
    } else {
      lineOptions.dashArray.forEach(function (dash) {
        if (!isPositiveNumber(dash)) {
          throw new Error("lineOptions \"".concat(name, "\" dashArray is not an array of positive numbers"));
        }
      });
    }
  } else {
    lineOptions.dashArray = null;
  }

  return lineOptions;
};
/**
 * * Checks that lineObject contains valid required properties
 * * lineObject.name and lineObject.data are required
 * * each object in data must contain an x and y property
 * * may mutate lineObject properties color, width and dashArray if they are invalid
 * * This method can be used to check if the lineObject is valid before calling the addDataLine method
 * @public
 * @param {LineObject} lineObject
 * @returns {boolean}
 * @throws If lineObject does not have the required properties
 */

export var checkLineObject = function checkLineObject(lineObject) {
  checkObject(lineObject, 'checkLineObject');

  if (!isString(lineObject.name) || !lineObject.name.length) {
    throw new Error('lineObject.name is not a string');
  }

  if (nameRe.test(lineObject.name)) {
    throw new Error("lineObject.name \"".concat(lineObject.name, "\" must contain only characters a-z, A-Z, 0-9, ., - or _"));
  }

  if (!isString(lineObject.label)) {
    lineObject.label = lineObject.name;
  }

  checkLineOptions(lineObject, lineObject.name);
  lineObject.data.forEach(function (datum, i) {
    if (!isNumber(datum.x)) {
      throw new Error("lineObject \"".concat(lineObject.name, "\" data[").concat(i, "].x is not a number"));
    }

    if (!isNumber(datum.y)) {
      throw new Error("lineObject \"".concat(lineObject.name, "\" data[").concat(i, "].y is not a number"));
    }
  });
  return true;
};
/**
 * * Checks that pointGroupObject contains valid pointGroupObject.name
 * * if pointGroupObject.data is not an array, it is created
 * * if pointGroupObject.data exists, each object in data must be a valid pointObject
 * * if pointGroupObject.shape is not supplied, it will be set to the default (circle) with the default radius
 * @param {PointGroupObject} pointGroupObject
 * @returns {boolean}
 * @throws If pointGroupObject does not have the required properties
 */

export var checkPointGroupObject = function checkPointGroupObject(pointGroupObject) {
  checkObject(pointGroupObject, 'checkPointGroupObject');

  if (!isString(pointGroupObject.name) || !pointGroupObject.name.length) {
    throw new Error('lineObject.name is not a string');
  }

  if (nameRe.test(pointGroupObject.name)) {
    throw new Error("pointGroupObject.name \"".concat(pointGroupObject.name, "\" must contain only characters a-z, A-Z, 0-9, ., - or _"));
  }

  if (!isString(pointGroupObject.label)) {
    pointGroupObject.label = pointGroupObject.name;
  }

  if (!isString(pointGroupObject.shape) || !shapes.includes(pointGroupObject.shape)) {
    pointGroupObject.shape = defaultOptions.point.shape;
    pointGroupObject.r = defaultOptions.point.r;
  }

  if (!isString(pointGroupObject.fill)) {
    pointGroupObject.fill = defaultOptions.point.fill;
  }

  if (!isString(pointGroupObject.stroke)) {
    pointGroupObject.stroke = defaultOptions.point.stroke;
  }

  if (Array.isArray(pointGroupObject.data)) {
    pointGroupObject.data.forEach(function (pointObject, i) {
      pointObject.name = pointGroupObject.name;
      checkPointObject(pointObject);
    });
  } else {
    pointGroupObject.data = [];
  }

  return true;
};
/**
 * * Checks that pointObject contains valid required properties
 * * pointObject.name is the name of the pointGroupObject this will be attached to
 * * must contain an x and  y property that are numbers
 * * if it contains a shape string, it must be a valid shape
 * * if it contains a fill (color) string, it must be a valid fill color
 * @throws If pointGroupObject does not have valid properties
 * @param {PointObject} pointObject
 */

export var checkPointObject = function checkPointObject(pointObject) {
  checkObject(pointObject, 'checkPointObject');

  if (!isNumber(pointObject.x)) {
    throw new Error("pointObject.x must be a number");
  }

  if (!isNumber(pointObject.y)) {
    throw new Error("pointObject.y must be a number");
  }

  if (!isString(pointObject.name)) {
    throw new Error("pointObject must have a name");
  }

  if (pointObject.r && !isNumber(pointObject.r)) {
    throw new Error("pointObject.r must be a number");
  }

  if (isString(pointObject.shape) && !shapes.includes(pointObject.shape)) {
    throw new Error("pointObject shape is invalid: \"".concat(pointObject.shape, "\""));
  }

  if (isString(pointObject.fill) && !isValidColor(pointObject.fill)) {
    throw new Error("pointObject fill is invalid: \"".concat(pointObject.fill, "\""));
  }
};