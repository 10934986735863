import React, {Component} from 'react';

const dashLengthValues = [0, 2, 4, 6];

/**
 * This component has 2 child components:
 *   1.  A drop-down box DashLength for selecting the number of dashes & spaces
 *   2.  A list of text input fields for setting the length of each dash & space
 */
export default class DashLineInput extends Component {

  constructor (props) {
    super(props);
    // const dashValues = new Array(dashLengthValues.length).fill(1);
    this.state = {
      dashLength: 0,
      dashValues: [5, 5, 10, 5, 20, 5]
    };
    this.setDashLength = this.setDashLength.bind(this);
    this.setDashValue = this.setDashValue.bind(this);
    this.setDashArray = this.setDashArray.bind(this);
  }

  setDashArray () {
    const { props, state } = this;
    if (props.setDashArray) {
      console.log('DashLineInput.setDashArray', state.dashValues);
      props.setDashArray(state.dashValues.slice(0, state.dashLength));
    }
  }

  setDashLength (evt) {
    evt.preventDefault();
    const dashLength = Number(this._dashLength.value);
    const { setDashArray } = this;
    this.setState( { dashLength }, setDashArray);
  }

  setDashValue (evt) {
    evt.preventDefault();
    const target = evt.target;
    const value = Number(target.value);
    const key = Number(target.dataset.key);
    // console.log('key', key);
    const { setDashArray } = this;
    this.setState(prevState => {
      const dashValues = [ ...prevState.dashValues ];
      dashValues[key] = value;
      return { dashValues };
    }, setDashArray);
  }

  componentDidUpdate () {
    const { props, state } = this;
    const dashLength = this._dashLength;
    if (props.dashLength !== state.dashLength) {
      this.setState(
        { dashLength: props.dashLength },
        () => {
          dashLength.value = props.dashLength;
        }
      );
    }
  }

  render () {
    let dashInputs = new Array(this.state.dashLength).fill(1);
    const { state, setDashLength, setDashValue } = this;
    const { dashValues } = state;
    const dashInputStyle = {
      display: 'inline-block',
      margin: '0 0.5em'
    };

    return (
      <div>
        <div style={{ display: 'inline'} }>
          <span style={{ marginRight: '1em'}}>Dash Length</span>
          <select onChange={setDashLength} ref={(c) => this._dashLength = c} >
            {
              dashLengthValues.map(v => {
                return (
                  <option key={v} value={v}>{v}</option>
                );
              })
            }
          </select>
        </div>
        {(this.state.dashLength > 0) &&
          <div style={{ display: 'inline' }}>
            <div style={{ marginLeft: '1em', display: 'inline' }}>
              {
                dashInputs.map((v, i) => {
                  let title;
                  if (i % 2) {
                    title = 'Pixels for spacing';
                  } else {
                    title = 'Pixels for dash';
                  }
                  return (
                    <input onChange={setDashValue} className="col-1 dashInput form-control roundedBorder" type='text' title={title}
                      style={ dashInputStyle } value={dashValues[i]} key={i} data-key={i} ref={c => this[`_dashInput_${i}`] = c}/>
                  );
                })
              }
            </div>
          </div>
        }
      </div>
    );
  }
}